import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import {
  setupLoadingScreenListeners,
  teardownLoadingScreenListeners,
} from "@/game-val/actions.mjs";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import Info from "@/game-val/Info.jsx";
import PlayerStats from "@/game-val/LoadingScreenPlayerStats.jsx";
import { valLoadingScreenRefs } from "@/game-val/refs.mjs";
import { getNameTag } from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import LoadingScreenData from "@/game-val/valorant-loading-screen-fixture.json";
import WinPrediction from "@/game-val/WinPrediction.jsx";
import registerOverlays from "@/util/register-overlays.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  aspect-ratio: 16/9;
  margin: auto;
  background: ${!IS_APP ? "aqua" : "initial"};
  overflow: hidden;
  position: relative;
  @media (min-aspect-ratio: 16/9) {
    width: calc(100vh * 16 / 9);
    height: 100%;
    font-size: 1.481vh;
  }
  @media (max-aspect-ratio: 16/9) {
    height: calc(100vw * 9 / 16);
    width: 100%;
    font-size: 0.834vw;
  }
`;

const AIWinPrediction = styled(WinPrediction)`
  position: absolute;
  right: var(--sp-5e);
  top: var(--sp-5e);
  width: calc(var(--sp-10e) * 6.45);
  height: calc(var(--sp-10e) * 2.65);
`;

const platformPath = getPlatformPath(false);

function LoadingScreen() {
  const { searchParams, currentPath, state: routeState } = useRoute();

  useEffect(() => {
    setupLoadingScreenListeners();
    return () => {
      teardownLoadingScreenListeners();
    };
  }, []);

  useLayoutEffect(() => {
    if (!IS_APP && !IS_NODE) {
      setFeatureFlag("ads", true);
      updateRoute(currentPath, searchParams, {
        gameName: LoadingScreenData.currentMatch.gameName,
        tagLine: LoadingScreenData.currentMatch.tagLine,
        queue: LoadingScreenData.currentMatch.queue,
        players: LoadingScreenData.currentMatch.players,
      });
    }
  }, [currentPath, searchParams]);

  const { players, queue } = routeState;

  const state = useSnapshot(readState);
  const {
    val: {
      profiles,
      playerActStats,
      playerMatchWinHistory,
      content: { acts },
    },
  } = state;
  const isLoadingScreenEnabled =
    state?.settings?.valorant?.overlays?.isLoadingScreenEnabled;

  const latestActId = acts.find((act) => act.endedAt === null)?.uuid;

  const playerTeamId = players?.find((p) => p.isMe)?.teamId;
  const orderedPlayers = useMemo(() => {
    if (!players || players.length === 0) return [];
    if (queue === "deathmatch" || queue === "snowball") {
      return players;
    } else if (players[0].teamId === playerTeamId) {
      return players;
    }

    return [
      ...players.slice(players.length / 2),
      ...players.slice(0, players.length / 2),
    ];
  }, [playerTeamId, players, queue]);

  const [playerTeamWinPercent, enemyTeamWinPercent] = useMemo(() => {
    let playerTeamTotal = 0;
    let playerTeamCount = 0;
    let enemyTeamTotal = 0;
    let enemyTeamCount = 0;
    for (const p of orderedPlayers) {
      const profile = profiles[getNameTag(p.gameName, p.tagLine)];
      const latestPlayerActStats =
        playerActStats[profile?.puuid]?.[latestActId]?.[platformPath];
      if (!latestPlayerActStats || latestPlayerActStats instanceof Error)
        continue;
      const actStats = latestPlayerActStats?.find((s) => s.queue === queue);
      if (!actStats) continue;
      if (actStats.matchesPlayed > 0) {
        const winPercent = (actStats.matchesWon / actStats.matchesPlayed) * 100;
        if (p.teamId === playerTeamId) {
          playerTeamTotal += winPercent;
          playerTeamCount += 1;
        } else {
          enemyTeamTotal += winPercent;
          enemyTeamCount += 1;
        }
      }
    }
    const playerTeamAvg =
      playerTeamTotal / (playerTeamCount > 0 ? playerTeamCount : 1);
    const enemyTeamAvg =
      enemyTeamTotal / (enemyTeamCount > 0 ? enemyTeamCount : 1);
    const totalAvg = playerTeamAvg + enemyTeamAvg;
    const playerTeamWinPercent =
      (playerTeamAvg / (totalAvg > 0 ? totalAvg : 1)) * 100;
    const enemyTeamWinPercent =
      (enemyTeamAvg / (totalAvg > 0 ? totalAvg : 1)) * 100;
    return [playerTeamWinPercent, enemyTeamWinPercent];
  }, [
    latestActId,
    orderedPlayers,
    playerActStats,
    playerTeamId,
    profiles,
    queue,
  ]);

  const isDeathmatch = queue === "deathmatch";
  const isSnowball = queue === "snowball";
  const shouldRender =
    orderedPlayers.length > 0 && playerTeamId && !isDeathmatch && !isSnowball;
  registerOverlays(GAME_SYMBOL_VAL, shouldRender ? ["loadingScreen"] : []);

  if (!shouldRender) return null;

  return (
    <Container>
      {isLoadingScreenEnabled ? (
        <>
          {orderedPlayers.map(({ gameName, tagLine }, index) => {
            const nameTag = getNameTag(gameName, tagLine);
            const profile = profiles[nameTag];
            if (!profile || profile instanceof Error) return null;
            const latestPlayerActStats =
              playerActStats[profile?.puuid]?.[latestActId]?.[platformPath];
            const actStats = !(latestPlayerActStats instanceof Error)
              ? latestPlayerActStats?.find((s) => s.queue === queue)
              : null;
            const winHistoryRaw =
              playerMatchWinHistory[profile?.puuid]?.[latestActId]?.[queue];
            const winHistory =
              winHistoryRaw &&
              !(winHistoryRaw instanceof Error) &&
              Array.isArray(winHistoryRaw)
                ? winHistoryRaw
                : [];
            return (
              <PlayerStats
                key={nameTag}
                matchHistory={winHistory}
                wins={actStats?.matchesWon}
                matches={actStats?.matchesPlayed}
                headshots={actStats?.headshots}
                bodyshots={actStats?.bodyshots}
                legshots={actStats?.legshots}
                kills={actStats?.kills}
                deaths={actStats?.deaths}
                assists={actStats?.assists}
                damage={actStats?.damagePerRound}
                score={actStats?.score}
                roundsPlayed={actStats?.roundsPlayed}
                rank={profile?.valorantProfile?.latestTier}
                index={index}
                totalPlayers={orderedPlayers.length}
              />
            );
          })}
          {playerTeamWinPercent && enemyTeamWinPercent ? (
            <AIWinPrediction
              playerTeamWinPercent={playerTeamWinPercent}
              enemyTeamWinPercent={enemyTeamWinPercent}
            />
          ) : null}
        </>
      ) : null}
      {isLoadingScreenEnabled && players?.length > 0 ? (
        <Info overlayId={OVERLAY_IDS.valLoadingMatchInfo} />
      ) : null}
      {valLoadingScreenRefs.VideoAd ? (
        <valLoadingScreenRefs.VideoAd
          overlayId={OVERLAY_IDS.valLoadingMatchAd}
        />
      ) : null}
    </Container>
  );
}

export function meta() {
  return {
    title: [null, "VALORANT - Loading Screen"],
    description: [null, "VALORANT Loading Screen overlay"],
  };
}

export default LoadingScreen;
