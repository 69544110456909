import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { Rank } from "@/game-val/OverlayPlayerRank.jsx";
import {
  calcWinrate,
  getOutcomeShort,
  getOutcomeShortColor,
  kdaColor,
} from "@/game-val/utils.mjs";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  position: absolute;

  --distance-y: calc(3.7503 * var(--sp-10e));
  --card-offset: calc(0.7101 * var(--sp-10e));
  --card-height: calc(2.5002 * var(--sp-10e));
  --distance-between-players: calc(1.008 * var(--sp-3e));
  --distance-x: calc(4.575 * var(--sp-10e));
  --current-card: ${({ $index, $totalPlayers }) =>
    $totalPlayers / 2 - ($index % Math.floor($totalPlayers / 2))};
  bottom: calc(
    var(--distance-y) + (var(--card-offset) * var(--current-card)) +
      (
        (var(--card-height) + var(--distance-between-players)) *
          (var(--current-card) - 1)
      )
  );
  transform-origin: bottom
    ${({ $index, $totalPlayers }) =>
      $index < $totalPlayers / 2 ? "left" : "right"} !important;
  left: ${({ $index, $totalPlayers }) =>
    $index < $totalPlayers / 2 ? "var(--distance-x)" : "auto"};
  right: ${({ $index, $totalPlayers }) =>
    $index >= $totalPlayers / 2 ? "var(--distance-x)" : "auto"};

  display: flex;
  flex-direction: ${({ $index, $totalPlayers }) =>
    $index < $totalPlayers / 2 ? "row" : "row-reverse"};
  width: calc(var(--sp-10e) * 8.875);
  height: calc(var(--sp-10e) * 2.5);
  background: var(--shade10-75);

  .outcome-list {
    margin: var(--sp-1e) 0;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-right: var(--sp-4e);
    margin-top: var(--sp-4_5e);
    margin-bottom: var(--sp-4_5e);
  }

  div.column:last-child {
    margin-right: 0;
  }

  .column.player-message {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    backdrop-filter: blur(var(--sp-2));
    background-color: var(--shade10-50);
    border-radius: var(--br);
    flex-direction: ${({ $index, $totalPlayers }) =>
      $index < $totalPlayers / 2 ? "row" : "row-reverse"};
    align-items: center;
    justify-content: flex-start;
  }

  p.stat-major {
    font-weight: 700;
    font-size: var(--sp-3_5e);
  }

  p.stat-minor {
    font-size: var(--sp-3e);
    color: var(--shade0-75);
  }
`;

const StatsContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Outcome = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sp-4e);
  height: var(--sp-4e);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  margin-right: var(--sp-0_5e);

  --background-color: var(--shade1-hsl);
  &.win {
    --background-color: var(--turq-hsl);
  }
  &.loss {
    --background-color: var(--red-hsl);
  }
  &.tie {
    --background-color: var(--shade3-hsl);
  }
  background: hsla(var(--background-color) / 0.25);
`;

const PlayerStats = ({
  wins = 0,
  matches = 0,
  headshots = 0,
  bodyshots = 0,
  legshots = 0,
  kills = 0,
  deaths = 0,
  assists = 0,
  damage = 0,
  score = 0,
  roundsPlayed = 0,
  matchHistory = [
    { won: true },
    { won: true },
    { won: true },
    { won: true },
    { won: true },
  ],
  rank = 0,
  index,
  totalPlayers = 0,
  isPrivate,
}) => {
  const state = useSnapshot(readState);
  const valConstants = state.val?.content;
  const ranks = valConstants?.ranks;
  const rankInfo = ranks?.find((r) => r.position === rank);

  const { t } = useTranslation();

  const winRate = calcWinrate({ wins, matches });
  const winRateString = displayRate(winRate, 100);
  const kda = calcRate(kills + assists, deaths, 1);
  const kdaString = kda.toLocaleString(getLocale(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const avgScore = calcRate(score, roundsPlayed);
  const avgScoreString = avgScore.toLocaleString(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const adr = calcRate(damage, matches);
  const adrString = adr.toLocaleString(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedHSPercent = displayRate(
    headshots,
    headshots + bodyshots + legshots,
    0,
    1,
  );

  const overlayId = `valLoadingMatchP${index + 1}`;

  if (!OVERLAY_IDS[overlayId]) return null;

  return (
    <Container $index={index} $totalPlayers={totalPlayers}>
      {isPrivate || matches === 0 ? (
        <div className="column player-message">
          <Rank rankInfo={rankInfo} isRankVisible />
          <p className="stat-minor">
            {isPrivate
              ? t("val:overlay.privatePlayer", "Player set to private.")
              : t("val:overlay.noMatches", "No matches found for this player.")}
          </p>
        </div>
      ) : null}
      <Rank rankInfo={rankInfo} isRankVisible />
      <StatsContainer>
        <div className="column">
          <p className="stat-major">
            {winRateString} {t("lol:winRate", "Win Rate")}
          </p>
          <p className="stat-minor">
            {t("val:stats.hsPercent", "{{hsPercent}} HS", {
              hsPercent: formattedHSPercent,
            })}
          </p>
          <Row className="outcome-list">
            {matchHistory.map(({ won }, index) => {
              const outcome = won ? "win" : "loss";
              return (
                <Outcome outcome={outcome} key={index}>
                  <span
                    className="type-mini"
                    style={{
                      color: getOutcomeShortColor(outcome),
                    }}
                  >
                    {getOutcomeShort(t, outcome)}
                  </span>
                </Outcome>
              );
            })}
          </Row>
        </div>
        <div className="column">
          <p className="stat-major" style={{ color: kdaColor(kda) }}>
            {t("val:stats.kda", "{{kda}} KDA", {
              kda: kdaString,
            })}
          </p>
          <p className="stat-minor">
            {t("val:stats.adr", "{{adr}} ADR", {
              adr: adrString,
            })}
          </p>
          <p className="stat-minor">
            {t("val:stats.avgScores", "{{avgScore}} Avg. Score", {
              avgScore: avgScoreString,
            })}
          </p>
        </div>
      </StatsContainer>
    </Container>
  );
};

export default PlayerStats;
